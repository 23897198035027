.profile-image {
    border: 5px solid rgba(0, 0, 0, 0.2);
}

.site-container {
    padding-top: 80px;
}

.icon {
    height: 21px;
    width: 21px;
    display: flex;
    svg {
        width: 21px;
        height: 21px;
    }
}

.icon.grey svg path {
    fill: #999;
}

.icon.red svg path {
    fill: #A00;
}

.list-title {
    text-transform: uppercase;
    font-weight: 300;
}

.navbar-toggler:focus {
    outline: none;
}

.navbar-toggler {
    background-color: rgba(255,255,255,0.1);
}

.navbar-toggler.collapsed {
    background-color: transparent;
}

h2[id]::before {
    display: block;
    height: 80px;
    margin-top: -80px;
    visibility: hidden;
    content: "";
}

.sticky-top.space-before {
    padding-top: 80px;
    margin-top: -80px;
}

@media print {
    body {
        -webkit-print-color-adjust: exact !important;
    }
    .jumbotron {
        padding: 0;
    }
    .badge {
        border: 0 !important;
    }
}

